import React from "react"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import Layout from "../components/Layout"
import Hero from "../components/widgets/Hero"
import ProductSlider from "../components/widgets/ProductSlider"
import Grid from "../components/widgets/Grid"

export default ({ data: { sanityStartPage }, pageContext }) => {
  const { widgets } = sanityStartPage

  return (
    <Layout meta={{ title: "Demo" }} pageContext={pageContext}>
      {widgets &&
        widgets.length &&
        widgets.map((widget) => {
          switch (widget.__typename) {
            case "SanityHero":
              return <Hero key={uuidv4()} data={widget} />
            case "SanityProductSlider":
              return (
                <ProductSlider
                  key={uuidv4()}
                  title={widget.title}
                  products={widget.products}
                  columns="4"
                />
              )
            case "SanityGrid":
              return <Grid key={uuidv4()} data={widget} />
            default:
              return null
          }
        })}
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityStartPage {
      widgets {
        ...Grid
        ...Hero
        ...ProductSlider
      }
    }
  }
`
