import React, { useContext } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { BrinkContext } from "../context/BrinkContext"
import {
  containerSmallMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../../constants"
import LineHeading from "../ui/LineHeading"
import Article from "./Article"
import Entry from "./Entry"
import ProductGridSlider from "./ProductGridSlider"

const Contianer = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 3rem auto;
  padding: 1rem 0;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0;
    margin: 6rem auto;
  }
`

const SubHeading = styled.p`
  font-size: 1.4rem;
  margin: -2rem auto 0;
  padding: 0 3rem 2.2rem;
  text-align: center;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0 3rem 3rem;
  }
`

const Entries = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1.5rem;
  align-items: center;
  flex-direction: ${(p) => (p.reverseOnMobile ? "column-reverse" : "column")};

  ${MEDIA_MIN_LARGE} {
    flex-direction: row;
    padding: 0;
  }
`

export default ({ data }) => {
  const { languageCode } = useContext(BrinkContext)
  const { title, subTitle, entries } = data

  const hasSliderWidget = entries.some(
    (e) => e.__typename === "SanityProductSlider"
  )

  return (
    <Contianer>
      {title && <LineHeading>{title[languageCode] || title.en}</LineHeading>}
      {subTitle && (
        <SubHeading>{subTitle[languageCode] || subTitle.en}</SubHeading>
      )}
      <Entries
        reverseOnMobile={entries[0].__typename === "SanityProductSlider"}
      >
        {entries &&
          entries.length &&
          entries.map((entry) => {
            switch (entry.__typename) {
              case "SanityArticle":
                return (
                  <Article
                    key={uuidv4()}
                    data={entry}
                    columns={entries.length}
                  />
                )
              case "SanityEntry":
                return (
                  <Entry
                    key={uuidv4()}
                    data={entry}
                    columns={hasSliderWidget ? 4 : entries.length}
                  />
                )
              case "SanityProductSlider":
                return (
                  <ProductGridSlider key={uuidv4()} products={entry.products} />
                )
              default:
                return null
            }
          })}
      </Entries>
    </Contianer>
  )
}
