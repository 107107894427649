import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM, MEDIUM } from "../../constants"
import Link from "../Link"

const Container = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  ${MEDIA_MIN_MEDIUM} {
    width: ${(p) =>
      p.columns
        ? `calc(100% / ${p.columns} - ${p.columns + 1}rem)`
        : "calc(100% / 2)"};

    &:not(:first-child):not(:last-child) {
      margin: 0 3rem 1.5rem;
    }
  }
`

const Image = styled(BackgroundImage)`
  background-image: url(${(p) => p.backgroundUrl});
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 25rem;
  margin-bottom: 0.2rem;

  ${MEDIA_MIN_MEDIUM} {
    height: 35rem;
  }
`

const Content = styled.div`
  width: 100%;
  color: ${(p) => p.theme.colors.black};
  padding: 2rem 1rem 4rem;
`

const Title = styled.h2`
  margin: 0;
  font-size: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2.2rem;
  }
`

const Text = styled.p`
  margin: 2rem auto 2rem;
  line-height: 2.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${MEDIA_MIN_MEDIUM} {
    margin: 1.6rem auto 2rem;
  }
`

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.colors.black};
  margin: 0 auto;
  display: inline-flex;

  i {
    margin-left: 0.5rem;
  }
`

export default ({ data, columns }) => {
  const { languageCode } = useContext(BrinkContext)
  const { colors } = useContext(ThemeContext)
  const {
    title,
    text,
    linkUrl,
    buttonText,
    mobileBackgroundImage,
    desktopBackgroundImage
  } = data

  const sources = [
    mobileBackgroundImage.asset.fluid,
    {
      ...desktopBackgroundImage.asset.fluid,
      media: `(min-width: ${MEDIUM}px)`
    }
  ]

  return (
    <Container columns={columns}>
      <Image fluid={sources} />
      <Content>
        <Title>{title[languageCode] || title.en}</Title>
        <Text>{text[languageCode] || text.en}</Text>
        <StyledLink underline to={linkUrl} color={colors.black}>
          {buttonText[languageCode] || buttonText.en}{" "}
          <i className="fal fa-long-arrow-right"></i>
        </StyledLink>
      </Content>
    </Container>
  )
}
